export const environment = {
  environmentLabel: null,
  activatedTenants: [3550, 3645, 3752, 3751, 3551, 3852, 3552],
  production: true,
  version: '1.10.2',
  commitHash: '967537915dde0552bc85d04fe2fb64bd1270c013',
  sentry: '',
  customerHubApiUrl: 'customer-hub-api.mlink.glb.ch',
  zulipUrl: 'https://zulip.glb.ch',
  zulipBucketUrl:
    'https://digizeit-api.mlink.glb.ch/api/zulip/uploads?token=$UPLOAD_TOKEN&path=',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'glb-group-customer-hub',
    bearerExcludedUrls: ['http://localhost:4202'],
    scope: 'openid email profile offline_access',
    twoFaTimeLimit: 120,
    logoutUrl: 'http://localhost:4202',
  },
  chatFileUploadWhiteList:
    'image/jpeg, image/gif, image/png, application/pdf, video/mp4, video/quicktime',
  chatFileUploadWhiteListMessage: '.jpeg, .jpg, .png, .gif, .pdf, .mp4, .mov',
};
